
import { defineComponent, computed, h } from 'vue'
import { NDivider, NTag, NTime, NAlert, useNotification } from 'naive-ui'
import { utils } from 'ethers'
import { useStore } from '@/store'
import {
  truncateAddr,
  fromBytes32,
  getDisplayName,
  getTokenByTokenID,
  nullToken,
} from '@/utils'
import { getTx, TxStatus, getStatus, getNftByTxHash } from '@/utils/nomadAPI'
import { isProduction, networks } from '@/config'
import Tag from './Tag.vue'
import NftPreview from '@/components/NFTPreview.vue'
import NotificationLink from '@/components/NotificationLink.vue'
import NotificationError from '@/components/NotificationError.vue'
import CopyHash from '@/components/CopyHash.vue'
import analytics from '@/services/analytics'

export default defineComponent({
  components: {
    NTag,
    NDivider,
    NTime,
    NAlert,
    CopyHash,
    NftPreview,
    NotificationLink,
    Tag,
  },
  setup: () => {
    const store = useStore()
    const notification = useNotification()
    const nomadSDK = store.getters.bridgeContext()

    return {
      connected: computed(() => store.state.wallet.connected),
      walletAddr: computed(() => store.state.wallet.address),
      config: nomadSDK.conf,
      notification,
      store,
    }
  },
  data: () => ({
    id: '',
    relayedAt: '',
    sendTx: '',
    processTx: '',
    originNet: '',
    originAddr: '',
    rawAmount: '',
    destNet: '',
    token: nullToken,
    tokenId: { domain: '', id: '' },
    amount: '',
    timeSent: undefined as Date | undefined,
    confirmAt: 0,
    status: undefined as number | undefined,
    TxStatus,
    readyToProcess: false,
  }),
  async mounted() {
    const { id } = this.$route.params
    this.id = id as string
    this.checkUrlParams(this.id)

    const bridgeContext = this.store.getters.bridgeContext()
    const tx = await getTx(this.id)
    if (!tx) {
      this.notification.error({
        title: 'Cannot fetch transaction',
        description:
          'Please refresh the page or contact support in our Discord server',
      })
      throw new Error('Unable to fetch transaction details')
    }
    this.sendTx = tx.dispatch_tx
    this.processTx = tx.process_tx
    this.originNet = bridgeContext.resolveDomainName(tx.origin_domain_id)
    this.destNet = bridgeContext.resolveDomainName(tx.destination_domain_id)
    this.originAddr = tx.sender_address
    this.rawAmount = tx.amount
    this.relayedAt = tx.relayed_at

    const tokenDomain = Number.parseInt(tx.message__token__domain)
    const resolvedTokenDomain = bridgeContext.resolveDomainName(tokenDomain)
    // get token
    this.tokenId = {
      domain: resolvedTokenDomain,
      id: fromBytes32(tx.message__token__id),
    }
    const tokenMetadata = getTokenByTokenID(this.tokenId)
    if (tokenMetadata) {
      this.token = tokenMetadata
      const amountBN = tx.amount
      this.amount = utils.formatUnits(amountBN, tokenMetadata.decimals)
    }

    // status
    try {
      await this.updateStatus()
    } catch (e) {
      console.error(e)
    }

    setInterval(async () => {
      if (!this.status || this.status < 4) {
        await this.updateStatus()
      }
    }, 60000)
  },

  methods: {
    truncateAddr,
    getDisplayName,
    checkUrlParams(id: string) {
      if (!id) {
        this.notification.error({
          title: 'Missing message id in URL',
          description: 'Please add the message id to the URL',
        })
        throw new Error(
          "Incomplete transaction URL, can't fetch transaction details"
        )
      }
      if (id.length !== 66) {
        this.notification.error({
          title: 'Invalid message ID',
          description: 'Please check that you have the correct ID',
        })
        throw new Error("Invalid message ID, can't fetch transaction details")
      }
    },
    setReadyToProcess(ready: boolean) {
      this.readyToProcess = ready
    },
    async updateStatus() {
      if (!this.id) return

      // fetch tx
      const tx = await getTx(this.id)

      if (!tx) {
        return (this.status = -1)
      }

      let state = getStatus(tx)

      if (tx.dispatched_at) {
        this.timeSent = new Date(Number.parseInt(tx.dispatched_at) * 1000)
      }

      if (state === TxStatus.RELAYED && tx.relayed_at) {
        const { optimisticSeconds } =
          this.config.protocol.networks[this.destNet].configuration
        // relayedAtt timestamp in seconds
        const relayedAt = Number.parseInt(tx.relayed_at)
        // confirmAt timestamp + 5 minute buffer
        this.confirmAt = relayedAt + optimisticSeconds + 300
      }
      this.processTx = tx.process_tx
      // set status after we have confirmAt
      this.status = state
    },
    async processMessage() {
      if (!this.id) {
        this.notification.error({
          title: 'Error completing transaction',
          description: 'Invalid id, check the url is correct',
        })
        return
      }
      try {
        await this.store.dispatch('processTx', this.id)
        analytics.track('Processed message', {
          walletAddress: this.walletAddr,
        })
        this.notification.success({
          title: 'Success',
          content: () =>
            h(NotificationLink, {
              text: 'View transaction on Etherscan',
              linkText: 'View on Etherscan',
              link: this.receiveLink,
            }),
        })
        await this.updateStatus()
      } catch (e: any) {
        const m = e.message.toLowerCase()
        if (m.includes('user denied') || m.includes('user rejected')) return
        this.notification.error({
          title: 'Error completing transaction',
          content: () =>
            h(NotificationError, {
              text: 'Please contact support in our Discord server',
              error: e as Error,
            }),
        })
        throw e
      }
      setTimeout(async () => {
        await this.updateStatus()
        if (!this.processTx) return
        // note: will not work with mock accountant in development, push to random nft
        if (!isProduction) this.$router.push('/recover/1')
        const nft = await getNftByTxHash(this.processTx)
        this.$router.push(`/recover/${nft.id_param.toString()}`)
      }, 3000)
    },
  },
  computed: {
    manualProcess(): boolean {
      const network = networks[this.destNet]
      if (!network) return false
      return network.manualProcessing
    },
    sendLink(): string {
      if (!this.originNet) return ''
      const n = networks[this.originNet]
      return `${n.blockExplorer}tx/${this.sendTx}`
    },
    receiveLink(): string {
      if (!this.destNet || !this.processTx) return ''
      const n = networks[this.destNet]
      return `${n.blockExplorer}tx/${this.processTx}`
    },
  },
})
