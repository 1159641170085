import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "main flex flex-col items-center m-auto relative" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_terms_modal = _resolveComponent("terms-modal")!
  const _component_bridge_modal = _resolveComponent("bridge-modal")!
  const _component_network_modal = _resolveComponent("network-modal")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_card_alert = _resolveComponent("card-alert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_terms_modal),
    (_ctx.$route.name === 'Bridge')
      ? (_openBlock(), _createBlock(_component_bridge_modal, { key: 0 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_network_modal),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Nav)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createCommentVNode(" Display if any Homes are in a failed state "),
      _createVNode(_component_card_alert, {
        show: _ctx.failedHomes.size > 0,
        title: "Under maintenance, temporarily unavailable:"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failedHomes, (domain, i) => {
            return (_openBlock(), _createElementBlock("span", {
              key: domain,
              class: "capitalize mr-2"
            }, [
              _createTextVNode(_toDisplayString(_ctx.getNetworkByDomainID(domain).name) + " ", 1 /* TEXT */),
              (i < _ctx.failedHomes.size - 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, ","))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["show"]),
      _createCommentVNode(" Network-specific App Alert "),
      _createCommentVNode(" uncomment, update warning text and network "),
      _createCommentVNode(" <network-alert /> "),
      _createCommentVNode(" page view "),
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Footer)
    ])
  ]))
}