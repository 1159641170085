import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between w-full" }
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = { class: "opacity-70 uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_back_outline = _resolveComponent("chevron-back-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_chevron_forward_outline = _resolveComponent("chevron-forward-outline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" pagination "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePage(_ctx.page - 1))),
          class: _normalizeClass(["bg-[#434343] h-8 w-8 mr-2 flex items-center justify-center rounded-sm cursor-pointer", { 'opacity-50': _ctx.page === 1 }])
        }, [
          _createVNode(_component_n_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_chevron_back_outline)
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePage(_ctx.page + 1))),
          class: _normalizeClass(["bg-[#434343] h-8 w-8 flex items-center justify-center rounded-sm cursor-pointer", { 'opacity-50': _ctx.page === _ctx.pageCount }])
        }, [
          _createVNode(_component_n_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_chevron_forward_outline)
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */)
      ]),
      _createElementVNode("div", _hoisted_3, "Page " + _toDisplayString(_ctx.page), 1 /* TEXT */)
    ])
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}