import { defineComponent } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import Recover from '@/views/Recover/Main.vue'
import Status from '@/views/Status/main.vue'
import History from '@/views/History/main.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsOfUse from '@/views/TermsOfUse.vue'
import CheckAllowed from '@/views/CheckAllowed.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/bridge',
    name: 'Bridge',
    component: defineComponent({}),
  },
  {
    path: '/recover/:id',
    name: 'Recover',
    component: Recover,
  },
  {
    path: '/history',
    name: 'History',
    component: History,
  },
  {
    path: '/tx/:id',
    name: 'Status',
    component: Status,
  },
  {
    path: '/allowed/:address?',
    name: 'Allow',
    component: CheckAllowed,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/terms',
    name: 'TermsOfUse',
    component: TermsOfUse,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: PageNotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
