import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDown = _resolveComponent("ChevronDown")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_collapse_transition = _resolveComponent("n-collapse-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.text), 1 /* TEXT */),
    _createElementVNode("div", {
      class: "flex items-center py-2 cursor-pointer opacity-70",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expand = !_ctx.expand))
    }, [
      _createVNode(_component_n_icon, { size: "16" }, {
        default: _withCtx(() => [
          _createVNode(_component_ChevronDown, {
            class: _normalizeClass(["transition-all", { 'rotate-180': _ctx.expand }])
          }, null, 8 /* PROPS */, ["class"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createVNode(_component_n_collapse_transition, { show: _ctx.expand }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.error.message), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ]))
}