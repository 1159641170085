import { NetworkMetadata, NetworkMap } from '@/config/types'

// import { AFFECTED_NETWORK } from '@/config'
import WETH from '@/assets/token-logos/WETH.svg'
import DEV from '@/assets/token-logos/GLMR.svg'
import AVAX from '@/assets/token-logos/AVAX.png'
import EVMOS from '@/assets/token-logos/EVMOS.svg'
import wADA from '@/assets/token-logos/WADA.svg'
import sWETH from '@/assets/token-logos/sWETH.png'
import gWETH from '@/assets/token-logos/gWETH.png'

const {
  VUE_APP_ETHEREUM_RPC,
  VUE_APP_MOONBEAM_RPC,
  VUE_APP_MILKOMEDA_RPC,
  VUE_APP_EVMOS_RPC,
  VUE_APP_XDAI_RPC,
  VUE_APP_AVALANCHE_RPC,
  VUE_APP_GOERLI_RPC,
  VUE_APP_SEPOLIA_RPC,
} = process.env

export const rpcs: Record<string, string[]> = {
  ethereum: [VUE_APP_ETHEREUM_RPC],
  moonbeam: [
    VUE_APP_MOONBEAM_RPC,
    'https://moonbeam.api.onfinality.io/public',
    'https://rpc.api.moonbeam.network',
  ],
  milkomedaC1: [VUE_APP_MILKOMEDA_RPC],
  evmos: [VUE_APP_EVMOS_RPC],
  avalanche: [VUE_APP_AVALANCHE_RPC],
  xdai: [VUE_APP_XDAI_RPC],
  goerli: [VUE_APP_GOERLI_RPC],
  sepolia: [VUE_APP_SEPOLIA_RPC],
}

export const { nomad, config } = await import('@nomad-xyz/sdk-bridge').then(
  async ({ BridgeContext }) => {
    const conf = await BridgeContext.fetchConfig(
      process.env.VUE_APP_NOMAD_ENVIRONMENT
    )
    const updatedRpcs = Object.assign(conf.rpcs, rpcs)
    conf.rpcs = updatedRpcs
    const nomad = new BridgeContext(conf).withDefaultBackend()
    console.log('nomad instantiated', nomad)
    return { nomad, config: conf }
  }
)

const networkIcons: any = {
  ethereum: WETH,
  moonbeam: DEV,
  avalanche: AVAX,
  evmos: EVMOS,
  milkomedaC1: wADA,
  goerli: gWETH,
  sepolia: sWETH,
}

export const getNetworksConfig = (): NetworkMap => {
  const networks: NetworkMap = {}

  Object.keys(config.bridgeGui).forEach((networkName) => {
    const { displayName, connections, manualProcessing } =
      config.bridgeGui[networkName]
    const { name, domain: domainID } = config.protocol.networks[networkName]
    const { chainId: chainID, blockExplorer } =
      config.protocol.networks[networkName].specs
    // use env values if available, else use rpcs provided by config
    const networkRPCs = rpcs[networkName] || config.rpcs[networkName]
    const rpcUrl = networkRPCs[0] // only 1 supported at the moment in the sdk
    const { optimisticSeconds } =
      config.protocol.networks[networkName].configuration
    const icon = networkIcons[networkName]

    networks[networkName] = {
      name,
      icon,
      rpcUrl,
      chainID,
      domainID,
      displayName,
      connections,
      blockExplorer,
      manualProcessing,
      optimisticSeconds,
    } as NetworkMetadata
  })

  return networks
}

export const getNetworksArr = (networks: NetworkMap): NetworkMetadata[] => {
  const networkArr = Object.values(networks)
  // const idx = networkArr.findIndex((el) => el.name == AFFECTED_NETWORK)
  // const ethNetwork = networkArr.splice(idx, 1)[0]
  // networkArr.unshift(ethNetwork)
  return networkArr
}
