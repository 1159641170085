
import { computed, defineComponent } from 'vue'
import analytics from '@/services/analytics'
import { useStore } from '@/store'
import { COINLIST_VERIFY_LINK } from '@/config'
import NomadButton from '@/components/Button.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

const connectWallet = {
  title: 'Connect Wallet',
  description: 'Connect the wallet that holds your Nomad assets.',
  img: require('@/assets/icons/step_wallet.svg'),
}

const notAllowed = [
  connectWallet,
  {
    title: 'Consolidate Assets',
    description: 'Combine all of your Nomad assets into a single wallet.',
    img: require('@/assets/icons/step_consolidate.svg'),
  },
  {
    title: 'Go to CoinList',
    description:
      'Verify now with CoinList Verify+ and link your wallet address. For more information, review the',
    img: require('@/assets/icons/step_coinlist.svg'),
  },
  {
    title: 'Wait and Check Status',
    description:
      'It can take up to 48 hours after your CoinList verification is successful, before you will be able to bridge. Check that you have completed all the steps and received a confirmation email.',
    img: require('@/assets/icons/step_wait.svg'),
  },
]

const allowed = [
  connectWallet,
  {
    title: 'Success',
    description: 'Your verification with CoinList Verify+ was successful.',
    img: require('@/assets/icons/step_coinlist-check.svg'),
  },
  {
    title: 'Bridge Back',
    description:
      'Bridge back all Nomad assets.  You must send 1 transaction for each type of asset.  It is recommended to send your entire holding.',
    img: require('@/assets/icons/step_bridge.svg'),
  },
  {
    title: 'Complete Transaction',
    description: 'Complete your transaction on Ethereum to receive your NFT.',
    img: require('@/assets/icons/step_nft.svg'),
  },
  {
    title: 'Access Recovered Funds',
    description: 'Use your NFT to access recovered funds pro rata.',
    img: require('@/assets/icons/step_access-funds.svg'),
  },
]

type Transition = 'forward' | 'back' | 'none'

export default defineComponent({
  components: { NomadButton, ConnectWallet },
  data: () => ({
    transitionType: 'none' as Transition,
    title: 'Join the recovery process',
    currentStep: 0,
  }),
  setup() {
    const store = useStore()

    return {
      userAddress: computed(() => store.state.wallet.address),
      userAllowed: computed(() => store.state.wallet.allowed),
      store,
    }
  },
  methods: {
    goToNextStep() {
      this.changeStep(this.currentStep + 1)
    },
    changeStep(step: number) {
      if (step === this.currentStep) return
      if (step > this.currentStep) {
        this.transitionType = 'forward'
      } else {
        this.transitionType = 'back'
      }
      this.currentStep = step
    },
    toCoinlistVerify() {
      analytics.track('Opened CoinList Verify Link', {
        walletAddress: this.userAddress,
      })
      window.open(COINLIST_VERIFY_LINK)
    },
    toRecoveryLink() {
      analytics.track('Opened Relauch Guide', {
        walletAddress: this.userAddress,
      })
      window.open(
        'https://medium.com/nomad-xyz-blog/nomad-bridge-relaunch-guide-3a4ef6624f90'
      )
    },
    toBridge() {
      this.$router.push('/bridge')
    },
  },
  computed: {
    steps() {
      return this.userAllowed ? allowed : notAllowed
    },
  },
})
