import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nomad_button = _resolveComponent("nomad-button")!

  return (_openBlock(), _createBlock(_component_nomad_button, { onClick: _ctx.handleConnect }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text || 'CONNECT WALLET'), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"]))
}