import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full max-w-[600px] relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nft_list = _resolveComponent("nft-list")!
  const _component_guide = _resolveComponent("guide")!

  return (_ctx.nfts)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createCommentVNode(" users who's wallet is connected and have NFTs see this "),
        (_ctx.nfts.length && _ctx.allowed)
          ? (_openBlock(), _createBlock(_component_nft_list, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createCommentVNode(" First time user with no NFTs or wallet not connected yet "),
              _createVNode(_component_guide)
            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}