
import { defineComponent, computed } from 'vue'
import { NInput } from 'naive-ui'
import { useStore } from '@/store'
import { toDecimals, toFixedDecimals } from '@/utils'
import { BigNumber } from '@ethersproject/bignumber'
import NomadButton from '@/components/Button.vue'
import AdvancedDropdown from '@/components/AdvancedDropdown.vue'

export default defineComponent({
  components: { NInput, NomadButton, AdvancedDropdown },
  setup: () => {
    const store = useStore()

    return {
      store,
      selectedToken: computed(() => store.state.userInput.token),
      sendAmount: computed(() => store.state.userInput.sendAmount),
      balances: computed(() => store.state.sdk.balances),
      toDecimals,
    }
  },
  data: () => ({
    title: 'How much would you like to bridge?',
    sendCustom: false,
    balance: undefined as BigNumber | undefined,
    amt: '',
    dropdownText:
      'It is strongly recommended to bridge back the full amount in a single transaction',
  }),
  async mounted() {
    this.balance = this.balances[this.selectedToken.key]
    if (this.balance) {
      this.store.dispatch('setSendAmount', this.formattedBalance)
    }
  },
  methods: {
    selectFull() {
      this.sendCustom = false
      if (this.balance) {
        this.store.dispatch('setSendAmount', this.formattedBalance)
      }
    },
    selectCustom() {
      this.sendCustom = true
      setTimeout(() => {
        ;(this.$refs.amount as any).focus()
      }, 100)
    },
  },
  watch: {
    async amt(newAmt) {
      console.log(newAmt)
      const formattedAmt = toFixedDecimals(newAmt, 6)
      this.store.dispatch('setSendAmount', formattedAmt || 0)
    },
    balance(newBalance) {
      if (newBalance && !this.sendCustom) {
        this.store.dispatch('setSendAmount', this.formattedBalance)
      }
    },
  },
  computed: {
    formattedBalance() {
      if (this.balance) {
        return toDecimals(this.balance, this.selectedToken.decimals, 6)
      }
      return 0
    },
    valid(): boolean {
      if (!this.sendAmount) return false
      return this.sendAmount > 0
    },
    subtitle() {
      const { symbol, affected } = this.selectedToken
      if (affected) {
        return `You will receive an NFT on Ethereum that can be used to access recovered ${symbol}`
      }
      return `This asset is not affected. Once your complete your bridge transfer on Ethereum, you will receive ${symbol} equal to the amount sent.`
    },
  },
})
